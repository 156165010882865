.navbar{
    background-color: #020279;
    width: 100%;
    box-shadow: 0 0 6px #020279;
    height: auto;
}

.titles{
    font-family: 'Work Sans', sans-serif;
    color: #efecec;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.titles:hover {
    color: rgb(255, 255, 255);
}

.logo{
    width: 60px;
    height: 50px;
    margin-left: 0.5rem;
    } 

    .home-item{
        margin-top: 0.75rem;
    }

    @media(min-width:988px) {
        .home-item{
            margin-top: 0;
        }
        }