.img_div{
    height: 400px; 
}

.img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card_container{
    border: transparent;
    background-color: white;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.174);
    padding: 0px;
}