.nos_contenemos{
    color: #020279;
    margin-bottom: 0;
    width: auto
}

.we_contain{
    color: #020279;
    margin-bottom: 0;
    width: auto
}

.img_container{
    width: 200px;
    height: 151px;
}

.about_text{
    color: #020279;
    text-align: justify;
    padding: 1rem;
    padding-bottom: 1rem;
}

@media (min-width: 320px) and (max-width: 768px) {
    .img_container{
        width: 170px;
        height: 129px;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .div_final{
        display: flex;
        flex-direction: column;
    }
  }
  

.img{
    height: 100%;
}

.div_final {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    width: 100%;
    margin-bottom: 1rem;
  }
  @media (min-width: 768px) {
  .nos_contenemos{
    padding-left: 1rem;
  }

  .we_contain{
    padding-right: 1rem;
  }
}