* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#contacto{
  width: 88%;
  margin-left: auto;
  margin-right: auto;
}

.ul_list{
  gap: 1rem;
  margin-right: 2rem;
}

footer {
  background-color: #020279;
  width: 100%;
  margin-top: auto;
  height: auto;
  box-shadow: 0 0 6px #020279;
}

.bi {
  color: white;
  width: 20px;
  height: 20px;
}

.ulises {
  display: flex;
  padding-left: 0px;
  margin-bottom: 0px;
}

.p-contacto {
  color: white;
}

.p-contactt {
  color: rgb(146, 146, 146);
  font-size: 14px;
  margin-bottom: 4rem;
}

.father-foot {
  height: 138px;
  width: 170px;
  margin-bottom: 1.5rem;
}

#contacto {
  border-bottom: 0.125rem solid #f8f8f8;
  padding-bottom: 2.5rem;
}

.g-map {
  width: 250px;
  height: 138px;
}

.little-bro {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.iconitos {
  color: #f8f8f8;
  transition: transform 0.3s ease;
}

.iconitos:hover {
  color: #BB2D2D;
  transform: scale(1.2);
}

.navbar {
  background-color: #020279;
  width: 100%;
  box-shadow: 0 0 6px #020279;
  height: auto;
}

.titles {
  font-family: "Work Sans", sans-serif;
  color: #efecec;
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
}

.titles:hover {
  color: rgb(255, 255, 255);
}

.logo {
  width: 60px;
  height: 50px;
  margin-left: 0.5rem;
}

.home-item {
  margin-top: 0.75rem;
}

@media (min-width: 988px) {
  .home-item {
    margin-top: 0;
  }
  .ul_list{
    gap: 5rem;
  }
}
:root {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  background-position: center;
  background-size: contain;
  width: 100%;
  margin-top: 55px;
}

h1 {
  font-family: "Bebas Neue", sans-serif;
  color: #BB2D2D;
  font-size: 60px;
  margin-bottom: 0px;
  margin-top: 30px;
  height: 70px;
}

h2 {
  font-family: "Work Sans", sans-serif;
  color: rgba(150, 147, 147, 0.7294117647);
  font-size: 18px;
  margin-top: 0.5rem;
  font-family: "Bebas Neue", sans-serif;
}

h5 {
  color: #020279;
}

.storage {
  width: 260px;
  height: 197px;
}

.image-front {
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: contain;
}

.cards {
  font-family: "Work Sans", sans-serif;
}

.logo-instagram,
.li-instagram {
  color: #004aad;
}

.imagen-nosotros {
  width: 286px;
  height: 248px;
}

.dead-one {
  display: none;
}

.sobre-nos {
  display: flex;
  justify-content: center;
  align-items: center;
}


#productos {
  scroll-margin-top: 11rem;
}

#sobre{
  scroll-margin-top: 11.7rem;
}



.card-title {
  color: #020279;
}

.card-text {
  color: #020279;
}

.cardies {
  border: transparent;
  background-color: white;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.174);
  padding: 0px;
}

.carousel {
  margin-top: 1rem;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.315);
  max-width: 1000px;
}

.carousel-item {
  height: 60vh;
  background-size: cover;
  background-position: center;
}

.back {
  height: 100%;
}

.bg-1 {
  background-image: url(../assets/img/foto-real-3.jpg);
}

.bg-2 {
  background-image: url(../assets/img/foto-real-1.jpg);
}

.bg-3 {
  background-image: url(../assets/img/foto-real-4.jpg);
}

.lined {
  border-bottom: 0.125rem solid #020279;
  margin-top: 3rem;
  text-align: center;
  width: 100%;
  padding-bottom: 1.5rem;
}

.floating {
  animation-name: floating;
  animation-duration: 1.7s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-top: 1.8rem;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
.about-us {
  border-radius: 0px;
  border: transparent;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.174);
  width: 100%;
  margin-bottom: 5rem;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description-us {
  text-indent: 3rem;
}

.como {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 900px;
}

.practice {
  color: #020279;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  text-align: justify;
}

.about-text {
  color: #020279;
  text-align: justify;
  padding: 1rem;
  padding-bottom: 3rem;
}

.fake-div {
  border-bottom: 0.07rem solid #020279;
  width: 40%;
  height: 0.5px;
}

.user-image {
  width: 150px;
  height: 150px;
}

.text-cards {
  text-align: justify;
}

.cool-items {
  color: #BB2D2D;
}

.we-conta-final {
  color: #020279;
  margin-bottom: 0;
  padding-right: 1rem;
  width: 90px;
}

.we-conta-prefinal {
  color: #020279;
  margin-bottom: 0;
  padding-left: 1rem;
  width: 130px;
}

.wasap_button {
  position: fixed; 
  transform: translateX(-50%); 
  color: #08af08;
  font-size: 4rem;
  transition: transform 0.3s ease;
  cursor: pointer;
  z-index: 9999;
}


.wasap_button:hover {
  transform: translateX(-50%) scale(1.1); 
}

@media screen and (min-width: 250px) and (max-width: 580px) {
  
  .wasap_button {
    font-size: 3rem;
    bottom: 1%; 
    right: -4%; 
  }
}
@media (min-width: 580px) {
  .wasap_button {
    font-size: 4rem;
    bottom: 2%; 
    right: 0%; 
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 70px;
    height: 85px;
  }
  h2 {
    font-size: 22px;
  }
  .about-us {
    width: 100%;
  }
}
@media (min-width: 1024px) {
  #home {
    font-size: 80px;
  }
  .about-us {
    width: 88%;
  }
  .lined {
    width: 88%;
    padding-bottom: 1.5rem;
    margin-top: 7rem;
  }
  #cooler {
    justify-content: flex-start;
  }
  .footer-container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
  }
  .ulises {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding-left: 0px;
    margin-bottom: 0px;
  }
  .little-bro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .g-map {
    margin-left: 2rem;
  }
  .father-foot {
    margin-right: 2rem;
  }
  .practice {
    margin-top: 2rem;
  }
  .about-text {
    margin: 0 3rem 0 3rem;
  }
  .li {
    border-bottom: 0.2rem solid transparent;
  }
  .li:hover {
    transition-duration: 0.9s;
    border-bottom: 0.2rem solid white;
  }
  .we-conta-final {
    margin-right: 3rem;
    width: auto;
  }
  .we-conta-prefinal {
    margin-left: 3rem;
    width: auto;
  }
}

