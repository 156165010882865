.input_holder{
    border-radius: 2px;
    border: none;
    height: 2rem;
    padding: .5rem;
}

.p_container{
    margin-left: 2rem;
    color: whitesmoke;
    margin-bottom: 0;
}

.send_button{
    width: 6rem;
    align-self: center;
    height: 2rem;
    border-radius: 2px;
    border: none;
    transition: background-color 0.3s ease-in-out;
}

.send_button:hover{
    background-color: rgb(174, 170, 170);
    color: black;
    border-radius: 2px;
    border: none;
}
.message_holder{
    min-height: 8rem;
    padding: .5rem;
    border-radius: 2px;
}

.location, .whatsapp, .mail, .instagram{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
}

  
.form{
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 1rem;
    margin-top: 4rem;
}
.info_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 1rem;
}
.icons_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
}

@media(min-width: 1024px) {
    .form{
        display: flex;
        flex-direction: column;
        width: 30%;
        gap: 1rem;
    }
    .info_container{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 4rem;
        margin-top: 1rem;
    }
    .icons_container{
        display: flex;
        flex-direction: column;
        width: 30%;
        gap: 1.5rem;
        justify-content: center;
    }
    
    .location, .whatsapp, .mail, .instagram{
        width: 100%;
    }
  }
  
  @media screen and (min-width: 315px) and (max-width: 368px) {
    .p_container {
      font-size: 13px; 
    }
  }