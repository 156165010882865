.banner {
    width: 88%;
    overflow: hidden;
    background-color: #bb2d2d; /* Cambia este color si deseas */
    white-space: nowrap;
    margin: 0 auto 0 auto;
    margin-top: 2.5rem;
  }
  
  .banner_text {
    display: inline-block;
    color: whitesmoke;
    padding: 10px 0;
    font-size: 2rem;
    font-weight: bold;
    animation: scroll-right 10s linear infinite;
    /* Espacio adicional entre frases */
    padding-right: 100px; /* Ajusta este valor para cambiar el espacio entre repeticiones */
  }
  
  /* Agrega repetición de la frase con espacio */
  
  @keyframes scroll-right {
    0% {
      transform: translateX(100vw); /* Comienza completamente fuera de la pantalla a la derecha */
    }
    100% {
      transform: translateX(-100%); /* Se desplaza completamente hacia la izquierda */
    }
  }
  