footer {
    background-color: #020279;
    width: 100%;
    margin-top: auto;
    height: auto;
    box-shadow: 0 0 6px #020279;
}

.bi {
    color: white;
    width: 20px;
    height: 20px;

}

.ulises {
    display: flex;
    padding-left: 0px;
    margin-bottom: 0px;
}

.p-contacto {
    color: white;
}

.p-contactt {
    color: rgb(146, 146, 146);
    font-size: 14px;
    margin-bottom: 4rem;
}

.father-foot {
    height: 138px;
    width: 170px;
    margin-bottom: 1.5rem;
}

#contacto {
    border-bottom: 0.125rem solid #f8f8f8;
    padding-bottom: 2.5rem;
}

.g-map {
    width: 250px;
    height: 138px;
}

.little-bro {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.c-one{
margin-left: 4rem;
width: 180px;
}

.c-two{
margin-left: 4rem;
width: 180px;
}

.c-three{
margin-left: 4rem;
width: 180px;
}

.c-four{
margin-left: 4rem;
width: 180px;
}

.iconitos{
    color: #f8f8f8;
    transition: transform 0.3s ease;
}

.iconitos:hover{
    color: #BB2D2D;
    transform: scale(1.2);
}