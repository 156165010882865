@media(min-width:1024px) {
    #home {
        font-size: 80px;
    }
    .about-us{
        width: 88%;
    }

    .lined{
        width: 88%;
        padding-bottom: 1.5rem;
        margin-top: 7rem;
    }

    #cooler{
        justify-content: flex-start;
    }
    
    .footer-container{
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: start;
    }

    .ulises{
        list-style: none;
        display: flex;
        flex-direction: row;
        padding-left: 0px;
        margin-bottom: 0px;
    }

    .little-bro{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .g-map{
        margin-left: 2rem;
    }
.father-foot{
    margin-right: 2rem;
}

    .practice{
        margin-top: 2rem;
    }

    .about-text{
    margin: 0 3rem 0 3rem;
    }

    .li{
        border-bottom: 0.2rem solid transparent;
    }
    
    .li:hover{
        transition-duration: 0.9s;
        border-bottom: 0.2rem solid white;
    }
    
    .we-conta-final{
        margin-right: 3rem;
        width: auto;
    }

    .we-conta-prefinal{
        margin-left: 3rem;
        width: auto;
    }
    .p-contacto{
        margin-bottom: 0;
    }

    }
