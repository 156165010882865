:root {
    scroll-behavior: smooth;
}


body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    background-position: center;
    background-size: contain;
    width: 100%;
    margin-top: 55px;
}

h1 {
    font-family: 'Bebas Neue', sans-serif;
    color: #BB2D2D;
    font-size: 60px;
    margin-bottom: 0px;
    margin-top: 30px;
    height: 70px;
}

h2 {
    font-family: 'Work Sans', sans-serif;
    color: #969393ba;
    font-size: 18px;
    margin-top: 0.5rem;
    font-family: 'Bebas Neue', sans-serif;
}

h5 {
    color: #020279;
}

.storage {
    width: 260px;
    height: 197px;
}

.image-front {
    width: 100%;
    height: 400px;
    background-position: center;
    background-size: contain;
}

.cards {
    font-family: 'Work Sans', sans-serif;
}

.logo-instagram,
.li-instagram {
    color: #004aad;
}

.imagen-nosotros {
    width: 286px;
    height: 248px;
}

.dead-one {
    display: none;
}

.sobre-nos {
    display: flex;
    justify-content: center;
    align-items: center;
}

#home,
#sobre,
#productos,
#contacto {
    scroll-margin-top: 80px;
}

.card-title {
    color: #020279;
}

.card-text {
    color: #020279;
}

// carousel ********


.carousel-inner{
    transition: margin-left 0.5s ease;
}
.carousel {
    margin-top: 1rem;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.315);
    max-width: 1000px;
}

.carousel-item {
    height: 60vh;
    background-size: cover;
    background-position: center;
}

.back {
    height: 100%;
    // background-color: rgba(249, 249, 249, 0.192);
}

.bg-1 {
    background-image: url(../assets/img/foto-real-4.jpg);
    background-size: cover;
    background-position: center;
}

.bg-2 {
    background-image: url(../assets/img/foto-real-3.jpg);
    background-size: cover;
    background-position: center;
}

.bg-3 {
    background-image: url(../assets/img/foto-real-1.jpg);
    background-size: cover;
    background-position: center;
}




.lined {
    border-bottom: 0.125rem solid #020279;
    margin-top: 3rem;
    text-align: center;
    width: 100%;
    padding-bottom: 1.5rem;
}

.floating {
    animation-name: floating;
    animation-duration: 1.7s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 1.8rem;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, -0px);
    }
}

.about-us {
    border-radius: 0px;
    border: transparent;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.174);
    width: 100%;
    margin-bottom: 5rem;
    padding: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.description-us{
    text-indent: 3rem;
}

.como{
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 900px;
}

.practice{
    color: #020279;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    text-align: justify;
}

.fake-div{
    border-bottom: 0.07rem solid #020279;
    width: 40%;
    height: .5px;
}
.user-image{
    width: 150px;
    height: 150px;
}

.text-cards{
    text-align: justify;
}

.cool-items{
    color: #BB2D2D;
}

.we-conta-final{
    color: #020279;
    margin-bottom: 0;
    padding-right: 1rem;
    width: 90px;
}

.we-conta-prefinal{
    color: #020279;
    margin-bottom: 0;
    padding-left: 1rem;
    width: 130px;
}

.div_final{
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
    width: 100%;
}

#contacto{
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    background-color: #BB2D2D;
}