@media(min-width:768px) {
    h1{
        font-size: 70px;
        height: 85px;
    }
    h2{
        font-size: 22px;
    }
    .about-us{
        width: 100%;
    }
    }