.thank_container{
    width: 100%;
   margin-top: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img{
    height: 100%;
    width: 100%;
}

.img_container{
    width: 15rem;
    margin: auto;
}
.button_container{
    display: flex;
    justify-content: center;
}

.card{
    box-shadow: rgba(0, 0, 0, 0.314) 0px 0px 15px 0px;
    width: 25rem;
}

@media (min-width: 220px) and (max-width: 420px) {
    .card{
        width: 18rem;
    }
   
  }

  @media (min-width: 220px) and (max-width: 768px) {

    .thank_container{
        margin-top: 0;
    }
  }